import cn from 'classnames';
import { Component, createRef } from 'react';

import { CssUiComponent } from '@webapp/ui/lib/survey-custom';

import { CustomStylesCtx } from '../custom-styles';

import css from './scrollarea.css';

// TODO check https://github.com/rommguy/react-custom-scroll

const MIN_THUMB_SIZE = 16;

const eventOptions = {
    capture: false,
    passive: false
};

export class Scrollarea extends Component<{
    className?: string;
    contentClassName?: string;
    scrollbarClassName?: {
        vertical?: string;
        horizontal?: string;
    };
    dragScroll?: boolean;
    scrollBarOffsetBottom?: number; // TODO use css class
    scrollBarOffsetTop?: number; // TODO use css class
    thumbColor?: string;
    vertical?: boolean;
    horizontal?: boolean;
    maxHeight?: number;
    minHeight?: number;
    stopScroll?: boolean;
    ref?: ForwardedRef<any>;
    hideScrollBar?: boolean;
    unobtrusiveBars?: boolean;
    onScroll?(x: number, y: number): void;
    onResize?(self: Component): void;
}> {
    public static defaultProps = {
        dragScroll: true,
        scrollbarClassName: {},
        horizontal: true,
        vertical: true,
        scrollBarOffsetBottom: 0,
        scrollBarOffsetTop: 0,
        stopScroll: false
    };

    private animationTimer = null;
    private content = createRef<HTMLDivElement>();
    private contentHeight = 0;
    private contentWidth = 0;
    private grabScroll = false;
    private height = 0;
    private horizontalRatio = 0;
    private horizontalThumb = createRef<HTMLDivElement>();
    private hscrl = createRef<HTMLDivElement>();
    private lastTouch: Touch;
    private lastX = 0;
    private lastY = 0;
    private maxScrollLeft = 0;
    private maxScrollTop = 0;
    private node = createRef<HTMLDivElement>();
    private resizeInterval: NodeJS.Timeout;
    private translateX = 0;
    private translateY = 0;
    private verticalRatio = 0;
    private verticalThumb = createRef<HTMLDivElement>();
    private vscrl = createRef<HTMLDivElement>();
    private width = 0;

    public componentDidMount(): void {
        const { horizontal, vertical } = this.props;

        this.content.current.addEventListener('touchstart', this.onTouchStart, eventOptions);
        this.content.current.addEventListener('touchmove', this.onTouchMove, eventOptions);
        this.content.current.addEventListener('mousedown', this.onDragStart, eventOptions);
        document.addEventListener('mouseup', this.onDragStop);
        this.content.current.addEventListener('mousewheel', this.onMouseWheel, eventOptions);

        if (vertical) {
            this.onVertical();
        }

        if (horizontal) {
            this.onHorizontal();
        }

        this.checkHeight();
        this.updateMaxScroll();
        this.updateThumbsSize();
        this.updateTranslate();

        clearInterval(this.resizeInterval);
        this.resizeInterval = setInterval(() => {
            const { offsetHeight: height, offsetWidth: width } = this.node.current;
            const { scrollHeight, scrollWidth } = this.content.current;
            if (
                width === this.width &&
                scrollWidth === this.contentWidth &&
                height === this.height &&
                scrollHeight === this.contentHeight
            ) {
                return;
            }

            this.width = width;
            this.contentWidth = scrollWidth;
            this.height = height;
            this.contentHeight = scrollHeight;

            this.onResize();
        }, 200);
    }

    public componentDidUpdate({ horizontal, vertical }): void {
        if (horizontal && !this.props.horizontal) {
            this.offHorizontal();
        }
        if (!horizontal && this.props.horizontal) {
            this.onHorizontal();
        }
        if (vertical && !this.props.vertical) {
            this.offVertical();
        }
        if (!vertical && this.props.vertical) {
            this.onVertical();
        }
        this.checkHeight();
        this.updateMaxScroll();
        this.updateThumbsSize();
        this.updateTranslate();
    }

    public componentWillUnmount(): void {
        clearInterval(this.resizeInterval);
        clearInterval(this.animationTimer);
        this.offVertical();
        this.offHorizontal();
    }

    public render(): ReactNode {
        const {
            children,
            className,
            contentClassName,
            hideScrollBar,
            horizontal,
            scrollbarClassName,
            scrollBarOffsetBottom,
            scrollBarOffsetTop,
            thumbColor,
            unobtrusiveBars,
            vertical
        } = this.props;
        const vBarStyle = {
            opacity: vertical ? '1' : '0',
            bottom: `${scrollBarOffsetBottom}px`,
            top: `${scrollBarOffsetTop}px`
        };
        const withScroll = this.withScroll();
        const thumbStyle = { backgroundColor: thumbColor ? thumbColor : this?.context?.imageSlider?.root?.color };

        return (
            <div
                ref={this.node}
                className={cn(CssUiComponent.SCROLL, css.scrollarea, className, {
                    [css.hideScrollBar]: hideScrollBar,
                    [css.vertical]: vertical,
                    [css.horizontal]: horizontal,
                    [css.grab]: this.grabScroll,
                    [css.unobtrusive]: unobtrusiveBars,
                    [css.withScroll]: withScroll
                })}
            >
                <div className={cn(CssUiComponent.SCROLL_CONTENT, css.content, contentClassName)} ref={this.content}>
                    {children}
                </div>
                {vertical && (
                    <div
                        ref={this.vscrl}
                        style={vBarStyle}
                        className={cn(
                            CssUiComponent.SCROLL_VBAR,
                            css.scrollbar,
                            css.vertical,
                            scrollbarClassName.vertical
                        )}
                    >
                        <div
                            className={cn(CssUiComponent.SCROLL_THUMB, css.thumb)}
                            ref={this.verticalThumb}
                            style={thumbStyle}
                        />
                    </div>
                )}
                {horizontal && (
                    <div
                        ref={this.hscrl}
                        className={cn(
                            CssUiComponent.SCROLL_HBAR,
                            css.scrollbar,
                            css.horizontal,
                            scrollbarClassName.horizontal
                        )}
                    >
                        <div
                            className={cn(CssUiComponent.SCROLL_THUMB, css.thumb)}
                            ref={this.horizontalThumb}
                            style={thumbStyle}
                        />
                    </div>
                )}
            </div>
        );
    }

    public scrollContent(deltaX = 0, deltaY = 0, animate = false): Promise<any> | boolean {
        if (!this.isScrollable(deltaX, deltaY)) {
            return false;
        }

        const targetX = this.calcScrollTargetX(deltaX);
        const targetY = this.calcScrollTargetY(deltaY);

        if (animate) {
            return this.animatedScroll(targetX, targetY);
        }

        this.translateX = targetX;
        this.translateY = targetY;
        this.updateTranslate();

        if (this.props.onScroll) {
            this.props.onScroll(this.translateX, this.translateY);
        }

        return true;
    }

    public updateMaxScroll = (): void => {
        this.maxScrollLeft = Math.max(this.content.current.scrollWidth - this.node.current.offsetWidth, 0);
        this.maxScrollTop = Math.max(this.content.current.scrollHeight - this.node.current.offsetHeight, 0);
    };

    public withScroll(): boolean {
        const { horizontal, vertical } = this.props;
        return (horizontal && this.maxScrollLeft > 1) || (vertical && this.maxScrollTop > 1);
    }

    private animatedScroll = (targetX, targetY): Promise<any> => {
        clearInterval(this.animationTimer);
        const tick = 15;
        const steps = 450 / tick;
        const stepX = targetX / steps;
        const stepY = targetY / steps;
        const xStep = this.translateX - targetX > 0 ? -stepX : stepX;
        const yStep = this.translateY - targetY > 0 ? -stepY : stepY;

        return new Promise((res, _) => {
            this.animationTimer = setInterval(() => {
                this.translateX = this.calcScrollTargetX(xStep);
                this.translateY = this.calcScrollTargetY(yStep);
                this.updateTranslate();
                if (this.translateX >= targetX && this.translateY >= targetY) {
                    clearInterval(this.animationTimer);
                    if (this.props.onScroll) {
                        this.props.onScroll(this.translateX, this.translateY);
                    }
                    res(this);
                }
            }, tick);
        });
    };

    private calcScrollTargetX = (deltaX = 0): number =>
        Math.min(Math.max(this.translateX + deltaX, 0), this.maxScrollLeft);

    private calcScrollTargetY = (deltaY = 0): number =>
        Math.min(Math.max(this.translateY + deltaY, 0), this.maxScrollTop);

    private checkHeight = (): void => {
        const {
            node: { current },
            props: { maxHeight, minHeight }
        } = this;

        if (typeof minHeight === 'number') {
            if (current.offsetHeight < minHeight) {
                current.style.height = `${minHeight}px`;
            }
        }

        if (typeof maxHeight === 'number') {
            if (current.offsetHeight > maxHeight) {
                current.style.height = `${maxHeight}px`;
            }
        }
    };

    private isScrollable = (deltaX = 0, deltaY = 0): boolean =>
        (deltaY < 0 && this.translateY !== 0) ||
        (deltaX < 0 && this.translateX !== 0) ||
        (deltaY > 0 && this.translateY !== this.maxScrollTop) ||
        (deltaX > 0 && this.translateX !== this.maxScrollLeft) ||
        false;

    private offHorizontal(): void {
        document.removeEventListener('mouseup', this.onHorizontalThumbMouse, eventOptions);
        document.removeEventListener('mousemove', this.onHorizontalThumbMouse, eventOptions);
        document.removeEventListener('touchend', this.onHorizontalThumbTouch, eventOptions);
        document.removeEventListener('touchmove', this.onHorizontalThumbTouch, eventOptions);
    }

    private offVertical(): void {
        document.removeEventListener('mouseup', this.onVerticalThumbMouse, eventOptions);
        document.removeEventListener('mousemove', this.onVerticalThumbMouse, eventOptions);
        document.removeEventListener('touchend', this.onVerticalThumbTouch, eventOptions);
        document.removeEventListener('touchmove', this.onVerticalThumbTouch, eventOptions);
    }

    private onDragMove = ({ clientX, clientY }: MouseEvent): void => {
        if (this.grabScroll && this.props.dragScroll) {
            void this.scrollContent(clientX - this.lastX, clientY - this.lastY);
            this.lastX = clientX;
            this.lastY = clientY;
        }
    };

    private onDragStart = ({ button, clientX, clientY, currentTarget }: MouseEvent): void => {
        if (button !== 0) return;

        if (this.content.current && currentTarget === this.content.current && this.props.dragScroll) {
            this.grabScroll = true;
            this.content.current.classList.add(css.grabMove);
            this.lastX = clientX;
            this.lastY = clientY;
            document.addEventListener('mousemove', this.onDragMove, eventOptions);
        }
    };

    private onDragStop = (): void => {
        document.addEventListener('mousemove', this.onDragMove, eventOptions);
        this.content.current && this.content.current.classList.remove(css.grabMove);
        this.grabScroll = false;
    };

    private onHorizontal(): void {
        this.horizontalThumb.current.addEventListener('mousedown', this.onHorizontalThumbMouse, eventOptions);
        this.horizontalThumb.current.addEventListener('touchstart', this.onHorizontalThumbTouch, eventOptions);
    }

    private onHorizontalThumbMouse = ({ button, screenX, type }: MouseEvent): void => {
        if (button !== 0) return;
        switch (type) {
            case 'mousedown':
                document.addEventListener('mouseup', this.onHorizontalThumbMouse, eventOptions);
                document.addEventListener('mousemove', this.onHorizontalThumbMouse, eventOptions);

                this.lastX = screenX;

                break;
            case 'mousemove':
                const deltaX = Math.round((screenX - this.lastX) / this.horizontalRatio);

                void this.scrollContent(deltaX, 0);
                this.lastX = screenX;

                break;
            case 'mouseup':
                document.removeEventListener('mouseup', this.onHorizontalThumbMouse, eventOptions);
                document.removeEventListener('mousemove', this.onHorizontalThumbMouse, eventOptions);

                break;
            default:
                break;
        }
    };

    private onHorizontalThumbTouch = (e: TouchEvent): boolean | void => {
        if (e.changedTouches.length !== 1) {
            return false;
        }

        switch (e.type) {
            case 'touchstart':
                e.preventDefault();

                document.addEventListener('touchend', this.onHorizontalThumbTouch, eventOptions);
                document.addEventListener('touchmove', this.onHorizontalThumbTouch, eventOptions);

                this.lastTouch = e.touches[0];

                break;
            case 'touchmove':
                const touch = e.touches[0];
                const deltaX = Math.round((touch.screenX - this.lastTouch.screenX) / this.horizontalRatio);

                void this.scrollContent(deltaX, 0);

                this.lastTouch = touch;

                break;
            case 'touchend':
                document.removeEventListener('touchend', this.onHorizontalThumbTouch, eventOptions);
                document.removeEventListener('touchmove', this.onHorizontalThumbTouch, eventOptions);

                break;
            default:
                break;
        }
    };

    private onMouseWheel = (e: WheelEvent): void => {
        if (this.scrollContent(e.deltaX, e.deltaY)) {
            if (this.props.stopScroll) {
                e.preventDefault();
            }
        }
    };

    private onResize = (): void => {
        this.updateMaxScroll();
        this.updateThumbsSize();
        this.updateTranslate();

        void this.scrollContent();

        if (this.props.onResize) {
            this.props.onResize(this);
        }

        this.forceUpdate();
    };

    private onTouchMove = (e: TouchEvent): void => {
        if (e.touches.length === 1) {
            const touch = e.touches[0];

            const deltaY = this.lastTouch.screenY - touch.screenY;
            const deltaX = this.lastTouch.screenX - touch.screenX;

            this.lastTouch = touch;

            if (this.scrollContent(deltaX, deltaY) && e.cancelable) {
                // e.preventDefault();
            }

            if (this.props.stopScroll) {
                e.preventDefault();
            }
        }
    };

    private onTouchStart = (e: TouchEvent): void => {
        if (e.touches.length === 1) {
            this.lastTouch = e.touches[0];
        }
    };

    private onVertical(): void {
        this.verticalThumb.current.addEventListener('mousedown', this.onVerticalThumbMouse, eventOptions);
        this.verticalThumb.current.addEventListener('touchstart', this.onVerticalThumbTouch, eventOptions);
    }

    private onVerticalThumbMouse = (e: MouseEvent): void => {
        if (e.button !== 0) return;

        e.stopPropagation();

        switch (e.type) {
            case 'mousedown':
                document.addEventListener('mouseup', this.onVerticalThumbMouse, eventOptions);
                document.addEventListener('mousemove', this.onVerticalThumbMouse, eventOptions);

                this.lastY = e.screenY;

                break;
            case 'mousemove':
                const deltaY = Math.round((e.screenY - this.lastY) / this.verticalRatio);

                void this.scrollContent(0, deltaY);
                this.lastY = e.screenY;

                break;
            case 'mouseup':
                document.removeEventListener('mouseup', this.onVerticalThumbMouse, eventOptions);
                document.removeEventListener('mousemove', this.onVerticalThumbMouse, eventOptions);

                break;
            default:
                break;
        }
    };

    private onVerticalThumbTouch = (e: TouchEvent): boolean | void => {
        if (e.changedTouches.length !== 1) {
            return false;
        }

        switch (e.type) {
            case 'touchstart':
                if (e.cancelable) {
                    e.preventDefault();
                }

                document.addEventListener('touchend', this.onVerticalThumbTouch, eventOptions);
                document.addEventListener('touchmove', this.onVerticalThumbTouch, eventOptions);

                this.lastTouch = e.touches[0];

                break;
            case 'touchmove':
                const touch = e.touches[0];
                const deltaY = Math.round((touch.screenY - this.lastTouch.screenY) / this.verticalRatio);

                void this.scrollContent(0, deltaY);

                this.lastTouch = touch;

                break;
            case 'touchend':
                document.removeEventListener('touchend', this.onVerticalThumbTouch, eventOptions);
                document.removeEventListener('touchmove', this.onVerticalThumbTouch, eventOptions);

                break;
            default:
                break;
        }
    };

    private updateThumbsSize = (): void => {
        const { hideScrollBar, horizontal, vertical } = this.props;

        this.node.current.classList.remove(css.vertical);
        this.node.current.classList.remove(css.horizontal);

        if (vertical) {
            this.vscrl.current.style.display = 'none';
            if (this.maxScrollTop > 1) {
                this.vscrl.current.style.display = 'block';
                !hideScrollBar && this.node.current.classList.add(css.vertical);
            }
        }

        if (horizontal) {
            this.hscrl.current.style.display = 'none';
            if (this.maxScrollLeft > 1) {
                this.hscrl.current.style.display = 'block';
                !hideScrollBar && this.node.current.classList.add(css.horizontal);
            }
        }

        if (vertical) {
            this.verticalRatio = this.content.current.offsetHeight / this.content.current.scrollHeight;
            this.verticalThumb.current.style.height = `${Math.max(
                this.vscrl.current.offsetHeight * this.verticalRatio,
                MIN_THUMB_SIZE
            )}px`;
        }

        if (horizontal) {
            this.horizontalRatio = this.content.current.offsetWidth / this.content.current.scrollWidth;
            this.horizontalThumb.current.style.width = `${Math.max(
                this.hscrl.current.offsetWidth * this.horizontalRatio,
                MIN_THUMB_SIZE
            )}px`;
        }

        this.updateMaxScroll();
    };

    private updateTranslate(): void {
        if (this.props.vertical) {
            this.content.current.scrollTop = this.translateY;
            const verticalBarRatio = this.vscrl.current.offsetHeight / this.content.current.offsetHeight;
            this.verticalThumb.current.style.top = `${this.translateY * verticalBarRatio * this.verticalRatio}px`;
        }

        if (this.props.horizontal) {
            this.content.current.scrollLeft = this.translateX;
            const horizontalBarRatio = this.hscrl.current.offsetWidth / this.content.current.offsetWidth;
            this.horizontalThumb.current.style.left = `${
                this.translateX * horizontalBarRatio * this.horizontalRatio
            }px`;
        }
    }
}

Scrollarea.contextType = CustomStylesCtx;
