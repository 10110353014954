import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';

import { API_URL } from '@webapp/common/conf';
import { setHtml } from '@webapp/common/lib/ui';
import { striptags } from '@webapp/common/lib/utils';
import { QuestionType } from '@webapp/common/resources/survey';
import { Checkbox } from '@webapp/ui/lib/checkbox';
import { CustomStylesCtx } from '@webapp/ui/lib/custom-styles';
import { Pic, Zoom } from '@webapp/ui/lib/icons';
import { CssPageBlock, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './select-image.css';

const Label: FC<{
    text: string;
}> = ({ text }) => {
    const { text: textStyle } = useContext(CustomStylesCtx);
    return <div className={cn(CssUiComponent.LABEL, css.text)} style={textStyle} {...setHtml(text)} />;
};

export const Image: FC<{
    index: number;
    gap: string;
    textPosition: 'above' | 'under';
    answer: any;
    onClick: (e) => void;
    type: any;
    selectImage: { resizer?: CSSProperties };
    onClickZoom: (index: number) => (event: ReactMouseEvent<SVGSVGElement, MouseEvent>) => void;
    aspect?: number;
}> = observer(
    ({
        answer: {
            file,
            response,
            value: [text]
        },
        aspect,
        gap,
        index,
        onClick,
        onClickZoom,
        selectImage: { resizer },
        textPosition,
        type
    }) => {
        const multiple = type === QuestionType.SELECT_ONE_IMAGE;
        const checked = Boolean(response.value);
        const zoom = useMemo(() => onClickZoom(index), [index, onClickZoom]);

        const imgFrameStyle: CSSProperties = useMemo(
            () => ({
                minWidth: !file ? 200 : undefined,
                '--border-color': resizer.color
            }),
            [file, resizer.color]
        );

        const imgStyle: CSSProperties = useMemo(
            () => ({
                aspectRatio: `${aspect}` || 'auto'
            }),
            [aspect]
        );

        const style = useMemo(() => ({ gap }), [gap]);

        return (
            <div className={cn(CssPageBlock.ANSWER, css.answer)} style={style}>
                {textPosition === 'above' && text && <Label text={text} />}
                <div className={cn(css.image, !file && css.empty)} style={imgFrameStyle} onClick={onClick}>
                    {file ? (
                        <img
                            alt={text ? striptags(text) : 'image'}
                            height={file.height}
                            loading='lazy'
                            src={API_URL + file.path}
                            style={imgStyle}
                            width={file.width}
                        />
                    ) : (
                        <Pic style={imgStyle} />
                    )}
                    <div className={css.checkbox}>
                        <Checkbox checked={checked} round={multiple} />
                    </div>
                    <Zoom className={css.zoomIcon} style={resizer} onClick={zoom} />
                </div>
                {textPosition === 'under' && text && <Label text={text} />}
            </div>
        );
    }
);
Image.displayName = 'Image';
