import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useState } from 'react';

import { useMobile } from '@webapp/common/hooks/use-mobile';
import { createTextStyle, getColor, setHtml } from '@webapp/common/lib/ui';
import type { ISurveyQuestionAnswerModel } from '@webapp/common/resources/mst-survey/question_answer';
import type { SurveyQuestion } from '@webapp/survey/src/components/questions/lib';
import { Checkbox } from '@webapp/ui/lib/checkbox';
import { CustomStylesCtx, useTextStyle } from '@webapp/ui/lib/custom-styles';
import { GradientNumbers } from '@webapp/ui/lib/gradient-numbers';
import { NumbersWithBorders } from '@webapp/ui/lib/numbers-with-borders';
import { Rating } from '@webapp/ui/lib/rating';
import { Slider } from '@webapp/ui/lib/slider';
import { CssPageBlock, CssQuestionType, CssUiComponent } from '@webapp/ui/lib/survey-custom';

import { Comment } from 'components/questions/components/comment';
import { CANTRATE_ID } from 'components/questions/constants';

import css from './csi.css';
// То что выводится в опросе и в превью просмотре (при редактировании)
const CsiRow: FC<{
    answer: ISurveyQuestionAnswerModel;
    style: React.CSSProperties;
    labelStyle: React.CSSProperties;
    color: any;
    count: any;
    size: any;
    matrixType: string;
    matrixNumbersColor: Array<string>;
    minAmount: number;
    maxAmount: number;
    csiFormat: string;
    comment?: boolean;
    commentText?: string;
    showCantRate?: boolean;
    commentRequired?: boolean;
    cantRateText?: string;
}> = observer(
    ({
        answer: {
            response,
            valid,
            value: [value1, value2, value3]
        },
        color,
        matrixNumbersColor,
        size,
        style,
        labelStyle,
        minAmount,
        maxAmount,
        csiFormat,
        comment,
        commentText,
        showCantRate,
        commentRequired,
        cantRateText,
        matrixType,
        count
    }) => {
        const [cantRate, setCantRate] = useState<boolean>(response.value === CANTRATE_ID);
        // Сделано для обратной совместимости
        const itemsCount = !maxAmount ? count : maxAmount + (!minAmount ? 1 : 0);
        const { textField: customStyles } = useContext(CustomStylesCtx);
        const isAnswerInvalid = valid === false;
        const isCommentInvalid = isAnswerInvalid && commentRequired && !response.comment;

        const onChange = useCallback(
            (v: number): void => {
                response.change(v);
                response.question?.changed?.();
            },
            [response]
        );
        const onChangeComment = useCallback(
            (v: string): void => {
                response.setComment(v);
                response.setCommentInvalid(!v);
                response.question.changed();
            },
            [response]
        );
        const onChangeExtra = useCallback(
            (v: number): void => {
                response.changeExtra?.(v as any);
                response?.question?.changed?.();
            },
            [response]
        );

        const handleCantRate = useCallback(
            (checked: boolean) => {
                setCantRate(checked);
                response.change(checked ? CANTRATE_ID : undefined);
            },
            [response]
        );

        const renderInput = (val: string | number | boolean, cb: (val: string | number) => void) => {
            if (csiFormat === 'numbers-with-borders') {
                return (
                    <NumbersWithBorders
                        disabled={cantRate}
                        colors={matrixNumbersColor}
                        itemsCount={itemsCount}
                        startsWith={minAmount}
                        selectedValue={val as number}
                        onClick={cb}
                    />
                );
            }
            if (csiFormat === 'slider') {
                return (
                    <Slider
                        disabled={cantRate}
                        min={minAmount}
                        max={maxAmount}
                        value={val as number}
                        withoutControl
                        rangeClassName={css.rangeSlider}
                        labelsBottom
                        trackBgImage={`linear-gradient(90deg, ${matrixNumbersColor.join(', ')})`}
                        onChange={cb}
                    />
                );
            }
            // Сделано доп условие для обратной совместимости
            if (csiFormat === 'numbers' || (!csiFormat && matrixType === 'numbers')) {
                return (
                    <GradientNumbers
                        disabled={cantRate}
                        size='small'
                        colors={matrixNumbersColor}
                        itemsCount={itemsCount}
                        startsWith={minAmount ?? 1}
                        selectedValue={val as number}
                        onClick={cb}
                    />
                );
            }

            return (
                <Rating
                    disabled={cantRate}
                    color={color}
                    count={itemsCount}
                    defaultValue={val as number}
                    size={size}
                    onChange={cb}
                />
            );
        };

        return (
            <>
                <div
                    className={cn(CssUiComponent.LABEL, css.label, css.mainLabel)}
                    style={{ ...style, ...labelStyle }}
                    {...setHtml(value1 as string)}
                />
                <div
                    className={cn(CssPageBlock.ANSWER, css.answer, {
                        [css.answer_invalid]: isAnswerInvalid
                    })}
                >
                    <div
                        className={cn(CssUiComponent.LABEL, css.label, { [css.answer_disabled]: cantRate })}
                        style={style}
                        {...setHtml(value2 as string)}
                    />
                    <div>{renderInput(cantRate ? undefined : response.value, onChange)}</div>
                </div>
                <div
                    className={cn(CssPageBlock.ANSWER, css.answer, {
                        [css.answer_invalid]: isAnswerInvalid
                    })}
                >
                    <div
                        className={cn(CssUiComponent.LABEL, css.label, { [css.answer_disabled]: cantRate })}
                        style={style}
                        {...setHtml(value3 as string)}
                    />
                    <div>{renderInput(cantRate ? undefined : response.extra, onChangeExtra)}</div>
                </div>
                {comment && (
                    <Comment
                        disabled={cantRate}
                        className={css.commentWrap}
                        commentInvalid={!cantRate && isCommentInvalid}
                        commentTextHelp={commentText}
                        showMark={commentRequired}
                        onChange={onChangeComment}
                        markColor={!cantRate && isCommentInvalid ? getColor('red') : customStyles['--c-border-focus']}
                    />
                )}
                {showCantRate && (
                    <div className={css.checkboxWrap}>
                        <Checkbox checked={cantRate} label={cantRateText} onChange={handleCantRate} />
                    </div>
                )}
            </>
        );
    }
);

export const Csi = observer<SurveyQuestion>(
    ({
        question: {
            answers,
            params: {
                amount,
                color,
                matrixNumbersColor,
                matrixType,
                size,
                minAmount,
                maxAmount,
                csiFormat,
                comment,
                commentText,
                cantRate,
                commentRequired,
                cantRateText
            },
            survey: {
                info: {
                    design: {
                        fonts: {
                            questionTextBold,
                            questionTextColor,
                            questionTextItalic,
                            questionTextSize,
                            questionTextThrough,
                            questionTextUnderline
                        }
                    }
                }
            }
        }
    }) => {
        const isMobile = useMobile();
        const { listStyle, textStyle } = useTextStyle();
        const adjustedSize = isMobile ? 1 : size;
        const headerTitleStyles: CSSProperties = createTextStyle({
            underline: questionTextUnderline,
            through: questionTextThrough,
            bold: questionTextBold,
            italic: questionTextItalic,
            color: questionTextColor,
            size: questionTextSize
        });

        return (
            <div className={cn(CssQuestionType.QUESTION, CssQuestionType.CSI, css.list)} style={listStyle}>
                {answers.map((a) => (
                    <CsiRow
                        labelStyle={headerTitleStyles}
                        answer={a}
                        color={color}
                        count={amount}
                        key={a.id}
                        matrixNumbersColor={matrixNumbersColor}
                        matrixType={matrixType}
                        size={adjustedSize}
                        style={textStyle}
                        minAmount={minAmount}
                        maxAmount={maxAmount}
                        csiFormat={csiFormat}
                        comment={comment}
                        commentText={commentText}
                        showCantRate={cantRate}
                        commentRequired={commentRequired}
                        cantRateText={cantRateText}
                    />
                ))}
            </div>
        );
    }
);
Csi.displayName = 'Csi';
